


























































import { Component, Vue } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

import InfoBlock from './components/InfoBlock.vue'
import CommentBlock from './components/CommentBlock.vue'

@Component({
  components: {
    InfoBlock,
    CommentBlock
  },
})
export default class Member extends Vue {
  get isH5() {
    return AppModule.isH5
  }

  get images() {
    return [
      'https://spics.inctrip.com/97f19128286cf5bb036f7ca4e6da0990.png',
      'https://spics.inctrip.com/ead38447ce48f3c72516664e23eefd39.png',
      'https://spics.inctrip.com/56d6e8e4c7842b02365fd51490a751bb.png',
      ''
    ]
  }
}
